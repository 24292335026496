@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

@layer base {
    body {
        @apply font-[Poppins];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(14, 69, 188, 0.8),
        hsla(166, 71%, 36%, 0.8)   
    )!important;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .recommendation {
    width: calc(25% - 20px); /* Four recommendations per row */
    margin: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .recommendation {
      width: calc(100% - 20px); /* One recommendation per row on smaller screens */
    }
  }